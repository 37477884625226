// Images file for 'enhancedremodel'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import CheckmarkCircle from '../../../../src/images/checkmark-circle-blue.png';
import SliderBubble from '../../../../src/images/slider-bubble.png';

export default {
  Favicon,
  Dropdown,
  CheckmarkCircle,
  SliderBubble,
};
